@import url("https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Rethink Sans", sans-serif;
}

.background {
  background: linear-gradient(
    180deg,
    #292621 0%,
    rgba(148, 146, 144, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 70vh;
}

.background-reset {
  background: linear-gradient(
    0deg,
    #292621 0%,
    rgba(148, 146, 144, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  height: 70vh;
}

.sign-up_background_step_2 {
  background-image: url("./assets/image\ \(1\).svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.sign-up_background_step_3 {
  background-image: url("./assets/image\ 6.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.sign-up_background_step_4 {
  background-image: url("./assets/image\ 7.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.sign-in_background {
  background-image: url("./assets/Frame 1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.verify-account_background {
  background-image: url("./assets/Frame 2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.reset-passport_background {
  background-image: url("./assets/image\ 4.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.verification-character {
  border-radius: 8px;
  border: 1px solid #96a397;
  background: #fff;
  height: 44px;
  padding: 12px 16px;
  color: #96a397;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-right: 9px;
}

.verification-character--selected {
  outline: none;
  border: 1px solid #2b8c34;
  box-shadow: 0px 0px 0px 2px #d5e8d6;
}

.sidebar_scroll::-webkit-scrollbar {
  width: 1px;
}

.sign_in_scroll::-webkit-scrollbar {
  width: 1px;
}

.select_scroll::-webkit-scrollbar {
  width: 1px;
}
.scroll::-webkit-scrollbar {
  width: 1px;
}

.shadow {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25),
    0px 16px 32px 0px rgba(0, 0, 0, 0.08);
}

table {
  border-radius: 8px;
  overflow: hidden;
  border: 0;
  border-collapse: collapse;
}

thead {
  background-color: #e1e6e1;
  border-radius: 8px;
  border: 0px solid #e1e6e1;
}

th thead {
  border: none;
  padding: 0px;
  /* border-radius: 8px; */
}

td {
  border: none;
  padding: 0px;
}

.auth_shadow {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25),
    0px 16px 32px 0px rgba(0, 0, 0, 0.08);
}

.coming-soon {
  background-image: url("./assets/curtain.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.react-datepicker {
  width: 100% !important;
}

.react-datepicker__month-container {
  width: 100% !important;
}
